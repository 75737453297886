<template>
  <travio-center-container grid-width="1/2" :pageTitle="'Add New Supplier Settings - ' + supplierName">
    <vx-card>
      <label class="text-sm">Name*</label>
      <vs-input class="w-full" v-model="form.name" v-validate="'required|max:25'" name="Name" />
      <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
      
      <div class="mt-4">
        <label class="text-sm">Description*</label>
        <vs-textarea v-model="form.description" v-validate="'required|max:500'" name="Description"  />
        <span class="text-danger text-sm">{{ errors.first('Description') }}</span>
      </div>

      <div class="mt-4">
        <label class="text-sm">Options</label>
        <v-select v-model="selectedOptions" multiple taggable append-to-body @input="setSelectedOptions" name="Options" />
        <span class="text-sm">Type the new option and press enter</span>
      </div>

      <div class="flex items-center mt-4" v-if="selectedOptions.length > 0">
        <vs-switch v-model="form.isOptionsMultiple" />
        <span class="ml-4">Is Options Multiple:</span>
      </div>
      
      <div class="mt-4" v-if="selectedOptions.length > 0">
        <label class="text-sm">Default Value</label>
        <v-select v-if="!form.isOptionsMultiple" :value="selectedDefaultValue" append-to-body :options="defaultValueOptions" @input="setSelectedDefaultValue"/>
        <v-select v-if="form.isOptionsMultiple" :value="selectedDefaultValue" multiple append-to-body :options="defaultValueOptions" @input="setSelectedDefaultValue"/>
      </div>
      <div v-if="selectedOptions.length === 0" class="mt-4">
        <label class="text-sm">Default Value</label>
        <vs-input class="w-full" v-model="form.defaultValue" v-validate="'max:500'" name="Default Value" />
        <span class="text-danger text-sm">{{ errors.first('Default Value') }}</span>
      </div>

      <div class="lg:float-left mt-6">
        <span class="text-sm text-danger">*Required Field</span>
      </div>
        
      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
      </div>
    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  components: {
    vSelect
  },
  props: {
    supplierId: { required: true },
  },
  data () {
    return {
      form: new Form({
        name: null,
        description: '',
        defaultValue: '',
        options: '',
        isOptionsMultiple: false,
      }),
      supplierName: '',
      selectedOptions: [],
      selectedDefaultValue: null,
      defaultValueOptions: []
    }
  },
  computed: {
  },
  mounted () {
    this.$vs.loading();
      this.$http.get(`api/admin/suppliers/${this.supplierId}/name`)
      .then(response => {
        this.supplierName = response.data
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
  },
  methods: {
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.post(`api/admin/suppliers/${this.supplierId}/settings`)
            .then(response => {
              this.$_notifySuccess('Supplier settings successfully saved');
              this.$router.push({ name: 'admin-supplier-edit', params: { supplierId: this.supplierId, tabIndexProp: 3 }})
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleCancel () {
      this.$router.push({ name: 'admin-supplier-edit', 
        params: { supplierId: this.supplierId, tabIndexProp: 3 }
      })
    },
    setSelectedOptions (value) {
      this.form.options = this.selectedOptions.join(",")
      this.defaultValueOptions = this.selectedOptions
    },
    setSelectedDefaultValue (value) {
      this.selectedDefaultValue = value
      // account for multiselected values
      if(value && value.constructor === Array) {
        this.form.defaultValue = value.join(",")
      } else {
        this.form.defaultValue = value
      }
    },
  }
}
</script>

<style>
 
</style>